<template>
  <section id="TertiaryNav">
    <b-container>
      <p>
        Luxury Awaits: 2023 US&nbsp;Open Individual Session Hospitality Ticket
        Packages are now available for purchase.
      </p>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "TertiaryNav",
};
</script>

<style lang="scss" scoped>
#TertiaryNav {
  position: fixed;
  top: 106px;
  left: 0;
  height: fit-content;
  min-height: 30px;
  width: 100%;
  background-color: #418fde;
  z-index: 99;
  // cursor: pointer;
  @media (max-width: $xl) {
    top: 104px;
  }
  @media (max-width: $lg) {
    top: 55px;
  }
  @media (max-width: $sm) {
    top: -100vh;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    background-color: rgba(#000000, 0.65);
    // background-color: rgba(#418fde, 0.95);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.mobileActive {
      top: 0;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 8px 10%;
    text-decoration: none;
    outline: none;
    @media (max-width: $md) {
      padding: 8px 12px;
    }
    @media (max-width: $sm) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      padding: 25px 15px;
      width: 80%;
      background-color: #418fde;
    }
    .close-icon {
      position: absolute;
      top: -50px;
      right: 0;
      cursor: pointer;
      @media (min-width: $sm) {
        display: none;
      }
    }
    p {
      font-family: "interstate", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      text-align: center;
      span {
        font-weight: 700;
      }
    }
  }
}
</style>