<template>
  <div id="TheOverlook">
    <!--<TertiaryNav v-if="purl === 'admin.0000' || purl === ''" :tertiaryNavData="tertiaryNavData" />-->
    <HeroCarousel :heroCarouselData="heroCarouselData" />
    <div class="gradient"></div>
    <TicketPackages
      :ticketPackagesHeader="ticketPackagesHeader"
      :packages="packages"
      :ticketPackageImages="ticketPackageImages"
      :hours="hours"
    />
    <div id="selectDateAnchor"></div>
    <SelectDate
      :dateCard="dateCard"
      dateCardTitle="THE OVERLOOK at the US Open"
    />
    <!-- <SelectDateNew
      :dateCard="dateCard"
      dateCardTitle="THE OVERLOOK at the US Open"
    /> -->
  </div>
</template>

<script>
import TertiaryNav from "@/components/Layout/TopNav/TertiaryNav";
import HeroCarousel from "@/components/patterns/HeroCarousel";
import TicketPackages from "@/components/patterns/TicketPackages";
import SelectDate from "@/components/patterns/SelectDate";
// import SelectDateNew from "@/components/patterns/SelectDateNew";
import { getOverlookHeroCarouselData } from "@/data/HeroCarousel/theOverlookHeroCarousel";
// import { getTicketPackagesHeader } from "@/data/TicketPackages/overlookTicketPackagesData";
import { getOverlookDateCardData } from "@/data/DateSelect/overlookDateCardData";
// import { getOverlookDateCardDataNew } from "@/data/DateSelect/overlookDateCardDataNew";
import axios from "axios";

export default {
  name: "TheOverlook",
  components: {
    TertiaryNav,
    HeroCarousel,
    TicketPackages,
    SelectDate,
    // SelectDateNew,
  },
  data() {
    return {
      selectDateVisible: false,
      heroCarouselData: getOverlookHeroCarouselData(),
      // ticketPackagesHeader: getTicketPackagesHeader(),
      ticketPackagesHeader: {
        header:
          "<p>An Overlook ticket package couples premium stadium seats + unlimited access to the US Open's largest hospitality club, with views overlooking the grounds. Whether you’re entertaining clients, prospects or friends, let the Overlook serve as a tranquil retreat before or in between matches.</p>",
      },
      dateCard: getOverlookDateCardData(),
      // dateCard: getOverlookDateCardDataNew(),
      packages: null,
      ticketPackageImages: [
        "theOverlook/overlook_the-details.jpg",
        "theOverlook/overlook_the-cuisine-the-drinks.jpg",
        "theOverlook/overlook_the-view.jpg",
      ],
      hours: [
        "Day Session: 10:30 a.m. - 3:30 p.m.",
        "Evening Session: 5:00 p.m. - 9:30 p.m.",
        "Sessions 24 + 25: 11:30 a.m. - 4:30 p.m.",
      ],
      tertiaryNavData: {
        text: "<b>HOLIDAY SALE!</b> Save up to 15% on multi-session Overlook packages, for a limited time. Click here for details.",
        offerId:
          "2021-deposit-for-hospitality-oknhbyo-13c63cd?fevoUri=2021-deposit-for-hospitality-oknhbyo-13c63cd%2F",
        // subOfferId: 'session-1-arthur-ashe-stadium-bxytkc5-2c2421a'
      },
      purl: null,
    };
  },
  mounted() {
    if (window.location.href.split("/")[3] == "new.5179") {
      this.selectDateVisible = true;
    } else {
      this.selectDateVisible = false;
    }

    window.scrollTo(0, 0);

    this.purl = this.$store.state.epitch.purl;

    var axel = Math.random() + "";
    var a = axel * 10000000000000;
    var axel2 = Math.random() + "";
    var b = axel2 * 10000000000000;

    let floodlightTag = document.createElement("script");
    let floodlightTag2 = document.createElement("script");
    floodlightTag.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=overl0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
        a +
        "?"
    );
    floodlightTag2.setAttribute(
      "src",
      "https://5526005.fls.doubleclick.net/activityi;src=5526005;type=usopen;cat=overl00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1;num=" +
        b +
        "?"
    );
    document.body.appendChild(floodlightTag);
    document.body.appendChild(floodlightTag2);

    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "2") {
          this.packages = JSON.parse(page.copy);
        }
      });
    }

    if (this.$store.state.epitch.ePitch.mode === "live") {
      const purl = this.$store.state.epitch.ePitch.purl;
      const epitchUrl = this.$store.state.epitch.url;
      axios.post(`${epitchUrl}/epitchApi/EpitchLiteTracking.php`, {
        method: "page",
        purl: purl,
        presentationId: window.presentationId,
        sessionDbId: window.sessionDbId,
        section: 2,
      });
    }

    let copy = this.$store.state.epitch.ePitch.pages;
    if (typeof copy !== "undefined") {
      copy.forEach((page) => {
        if (page.id === "2") {
          let description = JSON.parse(page.copy);
          let pageDescription = description.pageDescription;
          if (pageDescription) {
            this.ticketPackagesHeader.header = pageDescription;
          }
          // window.console.log('description', pageDescription)
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// #TheOverlook {
//   margin-top: 136px;
//   @media (max-width: $lg) {
//     margin-top: 85px;
//   }
//   @media (max-width: 609px) {
//     margin-top: 97px;
//   }
//   @media (max-width: 326px) {
//     margin-top: 110px;
//   }
// }
#selectDateAnchor {
  position: relative;
  // top: -150px;
  top: -136px;
  left: 0;
  @media (max-width: $lg) {
    // top: -100px;
    top: -85px;
  }
  @media (max-width: 695px) {
    top: -98px;
  }
  @media (max-width: 375px) {
    top: -111px;
  }
}
</style>

<style lang="scss">
.gradient {
  width: 100%;
  height: 17px;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>